"use client";
import { useEffect } from "react";
import { isProduction } from "@/app/external-analysis/utils";

export function HotjarScriptComponent() {
  useEffect(() => {
    if (!isProduction()) {
      console.log("Hotjar is disabled in non-production environments");
    }
    (function (h, o, t, j, a, r) {
      h.hj =
        h.hj ||
        function () {
          (h.hj.q = h.hj.q || []).push(arguments);
        };
      h._hjSettings = { hjid: 5071295, hjsv: 6 };
      a = o.getElementsByTagName("head")[0];
      r = o.createElement("script");
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
  }, []);

  return null;
}
