import { createParser } from "nuqs";
import dayjs from "dayjs";

export const parseAsDate = createParser({
  parse: (value: any) =>
    value && dayjs(value, "YYYY-MM-DD", true).isValid()
      ? dayjs(value, "YYYY-MM-DD", true)
      : null,
  serialize: (value: any) => (value ? value.format("YYYY-MM-DD") : null),
});
