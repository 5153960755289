import { createParser } from "nuqs";
import dayjs from "dayjs";
import { TimeFormat } from "@/lib/analytics/overall";

export const parseAsHourMinutes = createParser({
  parse: (value: any) =>
    value && dayjs(value, TimeFormat, true).isValid()
      ? dayjs(value, TimeFormat, true)
      : null,
  serialize: (value: any) => (value ? value.format(TimeFormat) : null),
});
