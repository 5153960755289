import dayjs from "dayjs";

export enum CompareMode {
  None = "0",
  PrecedingPeriod = "1",
  DayShift = "2",
  SamePeriodPrevMonth = "3",
}

export function compareDates(
  from: dayjs.Dayjs,
  to: dayjs.Dayjs,
  compareMode: CompareMode,
  compareShift?: number,
): {
  compareFrom: dayjs.Dayjs;
  compareTo: dayjs.Dayjs;
  compare: CompareMode;
  compareShift?: number;
} {
  let compareFrom, compareTo: dayjs.Dayjs;
  switch (compareMode) {
    case CompareMode.None:
      return {
        compare: CompareMode.None,
        compareFrom: null,
        compareTo: null,
      };
    case CompareMode.PrecedingPeriod:
      const precedingPeriodLength = to.diff(from, "day");
      compareFrom = from.add(-precedingPeriodLength - 1, "day");
      compareTo = to.add(-precedingPeriodLength - 1, "day");
      return {
        compare: CompareMode.PrecedingPeriod,
        compareFrom,
        compareTo,
      };
    case CompareMode.DayShift:
      compareFrom = from.add(-compareShift, "day");
      compareTo = to.add(-compareShift, "day");
      return {
        compare: CompareMode.DayShift,
        compareFrom,
        compareTo,
        compareShift,
      };
    case CompareMode.SamePeriodPrevMonth:
      compareFrom = from.add(-1, "month");
      compareTo = to.add(-1, "month");
      return {
        compare: CompareMode.SamePeriodPrevMonth,
        compareFrom,
        compareTo,
      };
    default:
      throw new Error(`Unknown compare mode: ${compareMode}`);
  }
}
