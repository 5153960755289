"use client";

import { createCache, extractStyle, StyleProvider } from "@ant-design/cssinjs";
import type Entity from "@ant-design/cssinjs/es/Cache";
import { ConfigProvider, theme } from "antd";
import { useServerInsertedHTML } from "next/navigation";
import React from "react";

// TODO save to cookies if dark mode on
const isDarkMode = () => {
  if (typeof window !== "undefined") {
    // Client-side-only code
    return (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    );
  }
  return false;
};

const StyledComponentsRegistry = ({ children }: React.PropsWithChildren) => {
  const cache = React.useMemo<Entity>(() => createCache(), []);
  useServerInsertedHTML(() => (
    <style
      id="antd"
      dangerouslySetInnerHTML={{ __html: extractStyle(cache, true) }}
    />
  ));
  return (
    <ConfigProvider
      theme={{
        algorithm: theme.defaultAlgorithm,
        token: {
          colorPrimary: "#506AF6",
        },
        components: {
          DatePicker: {
            // timeColumnWidth: 30,
            /* here is your component tokens */
          },
        },
        // algorithm: isDarkMode() ? theme.darkAlgorithm : theme.defaultAlgorithm,
      }}
    >
      <StyleProvider cache={cache}>{children}</StyleProvider>
    </ConfigProvider>
  );
};

export default StyledComponentsRegistry;
