import dayjs from "dayjs";

export const Overall = "overall";
export const TimeFormat = "HH:mm";

export function createTimeRange(str: string): [dayjs.Dayjs, dayjs.Dayjs] | [] {
  try {
    const parts = str.split("-").map((s) => s.trim());
    if (parts.length !== 2) {
      return [];
    }

    const result = parts.map((s) => dayjs(s, TimeFormat)) as [
      dayjs.Dayjs,
      dayjs.Dayjs,
    ];
    if (result[0].isValid() && result[1].isValid()) {
      return result;
    }
  } catch (error) {
    console.debug("[createTimeRange]", str, error);
  }

  return [];
}

export function timeRangeToString(
  range: [dayjs.Dayjs, dayjs.Dayjs] | [],
): string {
  if (!range || range.length === 0) {
    return "";
  }
  return `${range[0].format(TimeFormat)} - ${range[1].format(TimeFormat)}`;
}
