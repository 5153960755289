"use client";
import { User } from "@/app/__generated__/graphql";
import { useContext, createContext, useEffect } from "react";
import * as Sentry from "@sentry/nextjs";

const UserContext = createContext<User>(null);

export function useUser() {
  return useContext(UserContext);
}

// you need to create a component to wrap your app in
export function UserProvider({
  children,
  user,
}: React.PropsWithChildren<{ user?: User | null }>) {
  useEffect(() => {
    Sentry.setUser(user ? { id: user?.id } : null);
  }, [user]);
  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
}
