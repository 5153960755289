// Code generated by internal/query-builder/external_kpi/gen/gen.go, DO NOT EDIT.
// to regenerate run go generate ./... in the root of the project

export interface Metric {
  id: string;
  label: string;
  isDimension: false;
}

export interface Dimension {
  id: string;
  label: string;
  isDimension: true;
}

type PossibleColumns = Metric | Dimension;

export enum KnownMetric {
  AvgStareTime = "stare_time_avg",
  StaredNumber = "stared_count",
  BypassersNumber = "bypassers_count",
  EngagementsCount = "engagements_count",
  EngagementsPerCustomer = "engagements_per_customer",
  AvgSpeed = "speed_avg_ms",
  AvgCSAT = "csat_avg",
  CSATDetected = "csat_detected",
  CSATNotDetected = "csat_not_detected",
  OverallCsatEventsCount = "overall_csat_events_count",
  PeopleCount = "people_count",
}

export enum KnownDimension {
  Time = "time",
  Hour = "hour",
  Date = "date",
  Week = "week",
  Zone = "zone_id",
  ZoneInstance = "zone_instance_id",
  Shop = "shop_id",
  SensorType = "sensor_type",
  DataSource = "data_source",
  ShopName = "shop_name",
  ZoneName = "zone_name",
  DeviceMac = "device_mac",
  Person = "person_id",
  VisitStarted = "visit_started",
  VisitEnded = "visit_ended",
}

export enum KnownColumns {
  AvgStareTime = "stare_time_avg",
  StaredNumber = "stared_count",
  BypassersNumber = "bypassers_count",
  EngagementsCount = "engagements_count",
  EngagementsPerCustomer = "engagements_per_customer",
  AvgSpeed = "speed_avg_ms",
  AvgCSAT = "csat_avg",
  CSATDetected = "csat_detected",
  CSATNotDetected = "csat_not_detected",
  OverallCsatEventsCount = "overall_csat_events_count",
  PeopleCount = "people_count",

  Time = "time",
  Hour = "hour",
  Date = "date",
  Week = "week",
  Zone = "zone_id",
  ZoneInstance = "zone_instance_id",
  Shop = "shop_id",
  SensorType = "sensor_type",
  DataSource = "data_source",
  ShopName = "shop_name",
  ZoneName = "zone_name",
  DeviceMac = "device_mac",
  Person = "person_id",
  VisitStarted = "visit_started",
  VisitEnded = "visit_ended",
}

export const KnownColumnsToNameMap = {
  zone_id: "Zone Name",
  shop_id: "Shop Name",

  data_source: "data_source",
};

export const KnownMetrics: Metric[] = [
  {
    id: KnownMetric.AvgStareTime,
    label: "Avg. Dwell Time",
    isDimension: false,
  },
  {
    id: KnownMetric.StaredNumber,
    label: "Dwell Number",
    isDimension: false,
  },
  {
    id: KnownMetric.BypassersNumber,
    label: "Bypassers Number",
    isDimension: false,
  },
  {
    id: KnownMetric.EngagementsCount,
    label: "Engagements Number",
    isDimension: false,
  },
  {
    id: KnownMetric.EngagementsPerCustomer,
    label: "Avg. Engagements per Customer",
    isDimension: false,
  },
  {
    id: KnownMetric.AvgSpeed,
    label: "Avg. Speed",
    isDimension: false,
  },
  {
    id: KnownMetric.AvgCSAT,
    label: "C-SAT",
    isDimension: false,
  },
  {
    id: KnownMetric.CSATDetected,
    label: "Satisfaction detected",
    isDimension: false,
  },
  {
    id: KnownMetric.CSATNotDetected,
    label: "Neutral state",
    isDimension: false,
  },
  {
    id: KnownMetric.OverallCsatEventsCount,
    label: "Events Count",
    isDimension: false,
  },
  {
    id: KnownMetric.PeopleCount,
    label: "People Count",
    isDimension: false,
  },
];
export const KnownDimensions: Dimension[] = [
  {
    id: KnownDimension.Time,
    label: "Time",
    isDimension: true,
  },
  {
    id: KnownDimension.Hour,
    label: "Hour",
    isDimension: true,
  },
  {
    id: KnownDimension.Date,
    label: "Date",
    isDimension: true,
  },
  {
    id: KnownDimension.Week,
    label: "Week",
    isDimension: true,
  },
  {
    id: KnownDimension.Zone,
    label: "Zone",
    isDimension: true,
  },
  {
    id: KnownDimension.ZoneInstance,
    label: "Zone Instance",
    isDimension: true,
  },
  {
    id: KnownDimension.Shop,
    label: "Shop",
    isDimension: true,
  },
  {
    id: KnownDimension.SensorType,
    label: "Sensor type",
    isDimension: true,
  },
  {
    id: KnownDimension.DataSource,
    label: "Data Source",
    isDimension: true,
  },
  {
    id: KnownDimension.ShopName,
    label: "Shop Name",
    isDimension: true,
  },
  {
    id: KnownDimension.ZoneName,
    label: "Zone Name",
    isDimension: true,
  },
  {
    id: KnownDimension.DeviceMac,
    label: "Device Mac",
    isDimension: true,
  },
  {
    id: KnownDimension.Person,
    label: "Person",
    isDimension: true,
  },
  {
    id: KnownDimension.VisitStarted,
    label: "Visit Started",
    isDimension: true,
  },
  {
    id: KnownDimension.VisitEnded,
    label: "Visit Ended",
    isDimension: true,
  },
];
export const possibleColumns = [...KnownDimensions, ...KnownMetrics];
export const KnownMetricsMap: Record<string, Metric> = KnownMetrics.reduce(
  (acc, m) => {
    acc[m.id] = m;
    return acc;
  },
  {} as Record<string, Metric>,
);
export const KnownDimensionsMap: Record<string, Dimension> =
  KnownDimensions.reduce(
    (acc, m) => {
      acc[m.id] = m;
      return acc;
    },
    {} as Record<string, Dimension>,
  );

export const tooltips: Record<string, string> = {
  [KnownColumns.VisitStarted]:
    "When a customer satisfaction or neutral state was first detected in the store.",
  [KnownColumns.VisitEnded]:
    "When a customer satisfaction or neutral state was last detected in the store.",
  [KnownColumns.AvgStareTime]:
    "Reflects the typical duration a customer spends looking at a product or display.",
  [KnownColumns.StaredNumber]:
    "Counts how many customers stopped to look at items on the shelf or display.",
  [KnownColumns.BypassersNumber]:
    "Tallies the average number of people walking by without stopping at the shelf or display.",
  [KnownColumns.EngagementsCount]:
    "Tracks the total number of interactions with the shelf within a given time frame.",
  [KnownColumns.EngagementsPerCustomer]:
    "Indicates the average number of times a customer interacts with products on the shelf.",
  [KnownColumns.AvgSpeed]:
    "Measures the average pace of all customers passing by the shelf or display area.",
  [KnownColumns.AvgCSAT]:
    "Measures the overall customer satisfaction with their shopping experience, rated on a scale from 1 to 100. A higher score indicates greater satisfaction. Score above 80 indicates that a high percentage of customers are satisfied, while a score below 50 suggests that many customers are dissatisfied. Monitoring this score can guide improvements in service, product placement, and store layout to enhance the shopping experience.",
  [KnownColumns.CSATDetected]:
    "Percentage of customers with detected satisfaction.",
  [KnownColumns.CSATNotDetected]: "Percentage of customers with neutral state.",
  [KnownColumns.OverallCsatEventsCount]: "Total number of events.",
  [KnownColumns.PeopleCount]: "Number of people.",
};
