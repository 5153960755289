"use client";
import {
  parseAsArrayOf,
  parseAsBoolean,
  parseAsInteger,
  parseAsString,
  parseAsStringEnum,
  useQueryState,
  useQueryStates,
} from "nuqs";
import { createContext, useContext } from "react";
import dayjs from "dayjs";
import { KnownDimension, KnownMetric } from "@/lib/analytics/metrics";
import { parseAsDate } from "@/lib/usequerystate/parseAsDate";
import { parseAsHourMinutes } from "@/lib/usequerystate/parseAsHourMinutes";
import { TimeFormat } from "@/lib/analytics/overall";
import {
  compareDates,
  CompareMode,
} from "@/app/(dashboard)/analytics/_components/filter-date-range-compare-helpers";
import { useUser } from "@/lib/user";

const FiltersContext = createContext<[FilterValues, FilterUpdater]>([
  {} as any,
  {
    update: () => {
      console.log("no user context");
    },
  } as any,
]);

export function useFilters() {
  return useContext(FiltersContext);
}
export enum TimeSplit {
  Hour = KnownDimension.Hour,
  Day = KnownDimension.Date,
  Week = KnownDimension.Week,
}

export interface FilterValues {
  ruleId: string[];
  assignee: string[];
  alertStatus: boolean | null;
  alertMessageStatus: string[];
  calibrationStatus: string[];
  ZoneType: string;
  splitBy: string;
  zones: string[];
  shops: string[];
  selectedMetrics: KnownMetric[];
  from: dayjs.Dayjs;
  to: dayjs.Dayjs;
  fromStr: string;
  toStr: string;
  timeFrom: any;
  timeTo: any;
  timeFromStr: string;
  timeToStr: string;
  timeSplit: TimeSplit;
  compare: CompareMode;
  compareShift: number;
  compareFrom: dayjs.Dayjs;
  compareTo: dayjs.Dayjs;
  compareFromStr: string;
  compareToStr: string;
  dataSource: boolean;
}

interface FilterUpdater {
  setTimeFrom: <Shallow>(value: any) => Promise<URLSearchParams>;
  setTimeTo: <Shallow>(value: any) => Promise<URLSearchParams>;
  setFrom: <Shallow>(value: any) => Promise<URLSearchParams>;
  setTo: <Shallow>(value: any) => Promise<URLSearchParams>;
  update: (v: Partial<FilterValues>) => void;
}

function delayedSetter<T>(
  setter: (value: T) => Promise<URLSearchParams>,
  delay: number,
) {
  return (value: T) => {
    return new Promise<URLSearchParams>((resolve) => {
      setTimeout(() => {
        resolve(setter(value));
      }, delay);
    });
  };
}
export function useFixedFilterSet(): {
  values: FilterValues;
  updater: FilterUpdater;
} {
  const fromKey = "from";
  const toKey = "to";
  const timeFromKey = "timeFrom";
  const timeToKey = "timeTo";

  const [from, originalSetFrom] = useQueryState<dayjs.Dayjs>(
    fromKey,
    parseAsDate.withDefault(dayjs().add(-7, "day")),
  );
  const [to, originalSetTo] = useQueryState<dayjs.Dayjs>(
    toKey,
    parseAsDate.withDefault(dayjs()),
  );

  const setFrom = delayedSetter((v: dayjs.Dayjs) => {
    if (others.page !== 1) {
      updateOthers({ page: 1 });
    }
    return originalSetFrom(v);
  }, 10);
  const setTo = delayedSetter((v: dayjs.Dayjs) => {
    if (others.page !== 1) {
      updateOthers({ page: 1 });
    }
    return originalSetTo(v);
  }, 10);
  const fromStr = from.format("YYYY-MM-DD");
  const toStr = to.format("YYYY-MM-DD");

  const [timeFrom, setTimeFrom] = useQueryState<dayjs.Dayjs>(
    timeFromKey,
    parseAsHourMinutes,
  );
  const [timeTo, setTimeTo] = useQueryState<dayjs.Dayjs>(
    timeToKey,
    parseAsHourMinutes,
  );
  const timeFromStr = timeFrom ? timeFrom.format(TimeFormat) : "";
  const timeToStr = timeTo ? timeTo.format(TimeFormat) : "";

  const user = useUser();
  const defaultSelectedMetrics = user?.canViewCSATOnly
    ? [KnownMetric.CSATDetected]
    : [KnownMetric.StaredNumber, KnownMetric.BypassersNumber];

  const [others, updateOthers] = useQueryStates({
    page: parseAsInteger.withDefault(1),
    ruleId: parseAsArrayOf(parseAsString).withDefault([]),
    assignee: parseAsArrayOf(parseAsString).withDefault([]),
    alertMessageStatus: parseAsArrayOf(parseAsString).withDefault([]),
    alertStatus: parseAsBoolean.withDefault(null),
    calibrationStatus: parseAsArrayOf(parseAsString).withDefault(["PENDING"]),
    ZoneType: parseAsString.withDefault("SHELF"),
    splitBy: parseAsString.withDefault(KnownDimension.Shop),
    zones: parseAsArrayOf(parseAsString),
    shops: parseAsArrayOf(parseAsString),
    timeSplit: parseAsStringEnum(Object.values(TimeSplit)).withDefault(
      TimeSplit.Hour,
    ),
    dataSource: parseAsBoolean.withDefault(user?.isAdmin),
    compare: parseAsStringEnum(Object.values(CompareMode)).withDefault(
      CompareMode.None,
    ),
    compareShift: parseAsInteger.withDefault(1),
    selectedMetrics: parseAsArrayOf(
      parseAsStringEnum(Object.values(KnownMetric)),
    ).withDefault(defaultSelectedMetrics),
  });

  const { compareFrom, compareTo } = compareDates(
    from,
    to,
    others.compare,
    others.compareShift,
  );
  const compareFromStr = compareFrom ? compareFrom.format("YYYY-MM-DD") : "";
  const compareToStr = compareTo ? compareTo.format("YYYY-MM-DD") : "";

  const updateOthersFn = (args: Partial<FilterValues>) => {
    if (others.page !== 1 && !("page" in args)) {
      // @ts-ignore
      args = { ...args, page: 1 };
    }
    updateOthers(args);
  };

  return {
    values: {
      from,
      to,
      fromStr,
      toStr,
      timeFrom,
      timeTo,
      timeFromStr,
      timeToStr,
      compareFrom,
      compareTo,
      compareFromStr,
      compareToStr,
      ...others,
    },
    updater: {
      setFrom,
      setTo,
      setTimeFrom,
      setTimeTo,
      update: updateOthersFn,
    },
  };
}

// you need to create a component to wrap your app in
export function FiltersProvider({ children }: React.PropsWithChildren) {
  const { values, updater } = useFixedFilterSet();

  return (
    <FiltersContext.Provider value={[{ ...values }, updater]}>
      {children}
    </FiltersContext.Provider>
  );
}
