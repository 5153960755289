import(/* webpackMode: "eager", webpackExports: ["HotjarScriptComponent"] */ "/app/app/external-analysis/_hotjarScript.js");
;
import(/* webpackMode: "eager" */ "/app/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["FiltersProvider"] */ "/app/lib/analytics/filters-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/lib/AntdRegistry.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ApolloWrapper"] */ "/app/lib/apollo-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UserProvider"] */ "/app/lib/user.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["NuqsAdapter"] */ "/app/node_modules/nuqs/dist/adapters/next/app.js");
